<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="Marketplace" :description="'You have total '+pagination.totalRecords+' leads in the marketplace.'"></block-head>
          <block-content>
            <card no-padding>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header title="">
                    <card-tools>
                      <div class="form-inline flex-nowrap gx-3">
                        <div class="form-wrap w-150px">
                          <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                            <option value="">Bulk Action</option>
                            <option value="withdraw">Withdraw</option>
                          </select>
                        </div>
                        <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">Apply</nk-button>
                          </span>
                          <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button=""
                                       class="search-toggle"
                                       v-on:click="searchBarActive=true"
                                       :class="{active: searchBarActive}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="btn-toolbar-sep"></li>
                          <li>
                            <drop-down
                                icon="ni-filter-alt"
                                size="lg"
                                direction="right"
                                :dot="( filters.sold_status !== '' || filters.leads_status !== '' || filters.form !== '' ? 'primary':'')"
                                title="Leads Filter">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="l_sold_f">Sold Status</label>
                                    <b-select v-model="filters.sold_status" id="l_sold_f">
                                      <option value="">All</option>
                                      <option value="0">Available</option>
                                      <option value="1">Sold</option>
                                      <option value="2">Booked</option>
                                    </b-select>
                                  </column>
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="l_status_f">Lead Status</label>
                                    <b-select v-model="filters.leads_status" id="l_status_f">
                                      <option value="">All</option>
                                      <option v-for="status in leadStatuses" :key="'ls'+status.id" :value="status.id">{{ status.status_text }}</option>
                                    </b-select>
                                  </column>
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="l_type_f">Lead Type</label>
                                    <b-select v-model="filters.form" id="l_type_f">
                                      <option value="">All</option>
                                      <option value="other">Other</option>
                                    </b-select>
                                  </column>
                                </row>
                              </template>
                              <template v-slot:footer>
                                <nk-button type="dark" dim v-on:click="getLeads(true)">Filter</nk-button>
                                <a class="clickable" href="javascript:;" v-on:click="[filters.sold_status=filters.leads_status=filters.form='',getLeads(true)]">Reset Filter</a>
                              </template>
                            </drop-down>
                          </li><!-- li -->
                          <li>
                            <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                              <ul class="link-check">
                                <li><span>Order</span></li>
                                <li :class="{active: filters.display_order === 'asc'}"><a href="javascript:;" v-on:click="[filters.display_order='asc', getLeads(true)]">ASC</a></li>
                                <li :class="{active: filters.display_order === 'desc'}"><a href="javascript:;" v-on:click="[filters.display_order='desc', getLeads(true)]">DESC</a></li>
                              </ul>
                            </drop-down>
                          </li><!-- li -->
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      placeholder="Search: lead number/id"
                      :is-active="searchBarActive"
                      @do-search="doSearch"
                      :search-term="filters.search"
                      @close-search="[searchBarActive=false, doSearch('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="loadingData">
                  <ion-spinner></ion-spinner>
                </div>
                <template v-else>
                  <div class="card-inner p-0">
                    <special-table>
                      <table-row is-heading>
                        <table-col has-check-box>
                          <b-check-box id="select_all" v-model="selectAll"></b-check-box>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Lead#</span>
                        </table-col>
                        <table-col>
                          <span class="sub-text">Client</span>
                        </table-col>
                        <table-col break-point="lg">Phone</table-col>
                        <table-col break-point="mb">Status</table-col>
                        <table-col break-point="md">Price</table-col>
                        <table-col break-point="lg">Added</table-col>
                        <table-col class="nk-tb-col-tools"></table-col>
                      </table-row>

                      <table-row v-for="lead in leads" :key="'ld'+lead.id">
                        <table-col has-check-box>
                          <b-check-box :id="'lead'+lead.id"
                                       v-model="lead.selected"
                                       :key="'cb'+lead.id"
                                       :disabled="lead.sold_status != 0"></b-check-box>
                        </table-col>
                        <table-col>{{ lead.lead_number }}</table-col>
                        <table-col>
                          <user-small-card
                              :user-name="lead.first_name+' '+lead.last_name"
                              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
                        </table-col>
                        <table-col break-point="mb">{{ lead.phone_no }}</table-col>
                        <table-col break-point="md">
                          <span :style="{color: getSoldStatus(lead.sold_status).color}">{{ getSoldStatus(lead.sold_status).text }}</span>
                        </table-col>
                        <table-col break-point="lg">
                          <span class="tb-amount">{{ lead.price }} <span class="currency">&euro;</span> </span>
                        </table-col>
                        <table-col break-point="lg">{{ lead.added_time }}</table-col>
                        <table-col class="nk-tb-col-tools">
                          <ul class="nk-tb-actions gx-1">
                            <li>
                              <drop-down-list>
                                <list-item icon="ni-eye" text="View Details" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                                <list-item icon="ni-curve-left-down" text="Withdraw" v-if="lead.sold_status == 0" v-on:click="_withdrawLeads([lead])"></list-item>
                              </drop-down-list>
                            </li>
                          </ul>
                        </table-col>
                      </table-row>

                    </special-table>

                  </div>
                  <div class="card-inner" v-if="pagination.totalRecords > 10">
                    <pagination
                        v-model="pagination.page"
                        :records="pagination.totalRecords"
                        :per-page="10"
                        @paginate="paginationCallback"
                        theme="bootstrap4"
                        :options="{chunk: 5, texts:{count: '||'}}"/>
                  </div>
                </template>
              </template>
            </card>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>

import {IonContent, IonPage, IonSpinner} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import {BlockContent, BlockHead, MainBlock} from "@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {BCheckBox, BSelect} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import { useStore } from "vuex"
import 'flatpickr/dist/flatpickr.css';
import useMarketplace from "./Leads/useMarketplace";
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"

export default defineComponent({
  components: {
    BSelect,
    ListItem,
    DropDownList,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSpinner,
    Pagination,
  },
  setup() {

    // Constants
    const store         = useStore()
    const leadStatuses  = store.state.general_data.leads_statuses
    const { withdrawLeads } = useMarketplace()
    const { updateTracker } = useDataChangeTracker()

    const soldStatuses = {
      0: {
        text: 'Available',
        color: '#8094ae',
      },
      1: {
        text: 'Sold',
        color: '#28c76f',
      },
      2: {
        text: 'Booked',
        color: '#03a9f4',
      },
      default: {
        text: 'Unknown',
        color: '#e85347',
      }
    }

    // Reactive objects
    let filters = reactive({
      search: '',
      leads_status: '',
      sold_status: '',
      form: '',
      display_order: ''
    })
    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })

    // Reactive variables
    let bulkSelectedAction = ref('')
    let cancelToken        = false
    let leads              = ref([])
    let loadingData        = ref(false)
    let searchBarActive    = ref(false)
    let selectAll          = ref(false)

    // Reactive function
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in leads.value){
        if( leads.value[x].selected){
          selectedRecords.push( leads.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'withdraw'){
        _withdrawLeads( selectedRecords )
      }
    }
    const doSearch = (search) => filters.search = search
    const getLeads = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/marketplace/list', {
            page: pagination.page,
            leads_type: filters.form,
            leads_status: filters.leads_status,
            leads_sold_status: filters.sold_status,
            search: filters.search,
            order: filters.display_order
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              leads.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              leads.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }
    const paginationCallback = () => getLeads(true)
    const _withdrawLeads = ( _leads ) => {

      let ids = []
      for(let x in _leads){
        ids.push( _leads[x].id)
      }
      withdrawLeads(_leads, (success) => {
        if( success ){
          updateTracker('admin', ['leads_list'])
          // Remove the selected leads from the leads array
          for(let x in ids){
            for(let y in leads.value){
              if(leads.value[y].id === ids[x]){
                leads.value.splice(y,1)
                break;
              }
            }
          }
          // All deleted, no entry left in the advisor array? and previous pages exists
          if( !leads.value.length && pagination.page > 1 ){
            // Deduct from total records value
            pagination.totalRecords -= ids.length
            // Goto previous page
            pagination.page--
            getLeads(true)
          }
        }
      })
    }
    const getSoldStatus = (status) => {
      try{
        return soldStatuses[status]
      }catch (e){
        return soldStatuses.default
      }
    }

    // Watchers
    watch( ()=> filters.search, () => getLeads(true))
    watch(selectAll, (n) => {
      for(let x in leads.value){
        if(leads.value[x].sold_status != 0){
          continue;
        }
        leads.value[x].selected = n
      }
    })
    watch(() => store.state.dataChangeTracker.admin.marketplace, (n) => {
      if(n){
        updateTracker('admin', ['marketplace'], false) // reset data tracker
        getLeads(true)
      }
    })

    // Mounted
    onMounted(()=> getLeads())

    // Returns
    return {
      bulkAction,
      bulkSelectedAction,
      doSearch,
      filters,
      getLeads,
      getSoldStatus,
      leads,
      leadStatuses,
      loadingData,
      pagination,
      paginationCallback,
      searchBarActive,
      selectAll,
      _withdrawLeads,
    }

  }
});

</script>
